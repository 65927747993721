//时间戳转为年-月-日 时:分
const formatMinute = date => {
  var time = new Date(date * 1000);
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var day = time.getDate();
  var hour = time.getHours();
  var minute = time.getMinutes();
  return year + '-' + month + '-' + day + ' ' + hour.toString().padStart(2, 0) + ':' + minute.toString().padStart(2, 0);
};
//根据身份证号获取用户的生日、性别、年龄、身份证脱敏
const IdCard = (UUserCard, num) => {
  if (num == 1) {
    //获取出生日期
    let birth = UUserCard.substring(6, 10) + "-" + UUserCard.substring(10, 12) + "-" + UUserCard.substring(12, 14);
    return birth;
  }
  if (num == 2) {
    //获取性别
    if (parseInt(UUserCard.substr(16, 1)) % 2 == 1) {
      return "男";
    } else {
      return "女";
    }
  }
  if (num == 3) {
    //获取年龄
    var myDate = new Date();
    var month = myDate.getMonth() + 1;
    var day = myDate.getDate();
    var age = myDate.getFullYear() - UUserCard.substring(6, 10) - 1;
    if (UUserCard.substring(10, 12) < month || UUserCard.substring(10, 12) == month && UUserCard.substring(12, 14) <= day) {
      age++;
    }
    return age;
  }
  if (num == 4) {
    //身份证脱敏 412725*****063X
    let desensitization = UUserCard.replace(/^(.{6})(?:\d+)(.{4})$/, "$1****$2");
    return desensitization;
  }
}
module.exports = {
  formatMinute,
  IdCard
}
