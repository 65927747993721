<template>
  <div class="wrap">
    <div class="pre_top">
      <div class="pre_top_flex">
        <div class="pre_flex_center">
          <span class="pre_active">1</span>
          <!-- <van-image class="pre_info_img" :src="img1"></van-image
          > -->
          <span>证件上传</span>
        </div>
        <div class="pre_top_line"></div>
        <div class="pre_top_flex">
          <span class="pre_actived">2</span>

          <!-- <van-image class="pre_info_img" :src="img2"></van-image
          > -->
          <span>信息填写</span>
        </div>
      </div>
    </div>
    <div class="pre_explain">请确保图片清晰可见</div>
    <div class="pre_photo_title">身份证照片（必传）</div>
    <div class="pre_photo">
      <div>
        <van-image
          class="pre_photo_img"
          v-if="formData.positive"
          :src="formData.positive"
        ></van-image>
        <van-image
          class="pre_photo_img"
          v-else
          :src="localPositive"
        ></van-image>
      </div>
      <div class="pre_plus_out">
        <div class="pre_plus_img">
          <van-uploader
            :after-read="positiveAfterRead"
            multiple
            :max-count="1"
            class="pre_photo_plus"
            :accept="'image/*'"
          >
            <van-image class="pre_photo_plus" :src="plusImg" />
          </van-uploader>
        </div>
        <div class="pre_plus_span">上传身份证正面（人像）</div>
      </div>
    </div>
    <div class="pre_photo">
      <div>
        <van-image
          class="pre_photo_img"
          v-if="formData.other"
          :src="formData.other"
        ></van-image>
        <van-image class="pre_photo_img" v-else :src="localOther"></van-image>
      </div>
      <div class="pre_plus_out">
        <div class="pre_plus_img">
          <van-uploader
            :after-read="otherAfterRead"
            multiple
            :max-count="1"
            class="pre_photo_plus"
            :accept="'image/*'"
          >
            <van-image class="pre_photo_plus" :src="plusImg" />
          </van-uploader>
        </div>
        <div class="pre_plus_span">上传身份证反面（国徽）</div>
      </div>
    </div>
    <div class="pre_photo_title">银行卡照片（非必传）</div>
    <div class="pre_photo pre_marginb">
      <div>
        <van-image
          class="pre_photo_img"
          v-if="formData.bank_pic"
          :src="formData.bank_pic"
        ></van-image>
        <van-image
          class="pre_photo_img"
          v-else
          :src="localBank_pic"
        ></van-image>
      </div>
      <div class="pre_plus_out">
        <div class="pre_plus_img">
          <van-uploader
            :after-read="bankPicUpload"
            multiple
            :max-count="1"
            class="pre_photo_plus"
            :accept="'image/*'"
          >
            <van-image class="pre_photo_plus" :src="plusImg" />
          </van-uploader>
        </div>
        <div class="pre_plus_span">上传银行卡照片</div>
      </div>
    </div>
    <div class="pre_tab">
      <div class="pre_tabL" @click="toAddfankui">
        <span class="iconfont icon-kefu font_size"></span>
      </div>
      <div class="pre_tabR" @click="toNextInfo">下一步</div>
    </div>
  </div>
</template>
<script>
import { IdCard } from "../utils/tool";
export default {
  data() {
    return {
      img1: require("../assets/images/info1.png"),
      img2: require("../assets/images/info2.png"),
      localPositive: require("../assets/images/positive.png"),
      localOther: require("../assets/images/other.png"),
      localBank_pic: require("../assets/images/account.png"),
      plusImg: require("../assets/images/plus.png"),
      pid: "",
      access_token: "",
      pnid: "",
      positive: "", //正面照
      other: "", //反面照
      bank_pic: "", //银行卡照
      formData: {
        other: "",
        positive: "",
        name: "",
        card_code: "",
        phone: "",
        bank_account: "",
        bank_title: "",
        address: "",
        bank_pic: "",
      },
    };
  },
  mounted() {
    let access_token = localStorage.getItem("access_token");
    this.access_token = access_token;
    let pid = localStorage.getItem("pid");
    this.pid = pid;
    let pnid = localStorage.getItem("pnid");
    this.pnid = pnid;
    this.getPhotoInfo();
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#F6F7FA");
  },
  beforeDestroy() {
    document.querySelector("body").removeAttribute("style");
  },
  methods: {
    toAddfankui() {
      this.$router.push({ name: "fankui" });
    },
    //获取个人信息
    getPhotoInfo() {
      this.$axios
        .get(
          "/tag/info?table=person&table2=person_salary&fieldone=id&fieldtwo=person_id&id= " +
            this.pid +
            '&fieldasname={"name":"username","card_code":"usercard_code","phone":"phone","address":"address","pic":"pic","status":"userstatus","shiming":"shiming_b"}'
        )
        .then((res) => {
          if (res.data.success) {
            let returnData = res.data.data;
            let isArray = returnData instanceof Array;
            let phone = localStorage.getItem("mobile");
            if (!isArray) {
              if (phone != "") {
                this.formData.phone = phone;
              } else {
                this.formData.phone = returnData.phone;
              }
              this.formData.shiming_b = returnData.shiming_b;
              this.formData.mobile = phone;
              this.formData.name = returnData.name;
              this.formData.card_code = returnData.card_code;
              localStorage.setItem("card_code", this.formData.card_code);
              this.formData.bank_account = returnData.bank_account;
              this.formData.bank_title = returnData.bank_title;
              this.formData.address = returnData.address;
              this.formData.positive = returnData.pic.split(",")[0];
              this.formData.other = returnData.pic.split(",")[1];
              this.formData.bank_pic = returnData.bank_pic;
              this.formData.gender = returnData.gender;
              this.formData.birthday = returnData.birthday;
            } else {
              this.$toast({ message: res.data.msg });
            }
          } else {
            this.$toast({ message: res.data.msg });
          }
        })
        .catch(() => {});
    },
    //上传正面照
    positiveAfterRead(file) {
      const param = new FormData();
      param.append("file", file.file);
      param.append("model", "front");
      param.append("pid", this.pid);
      this.$axios
        .post("/user/uploadEducation", param, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.access_token,
          },
        })
        .then((res) => {
          let returnData = res.data.data;
          if (res.data.data.code == 0) {
            this.formData.positive = returnData.imgurl;
            this.formData.name = returnData.data.姓名.words;
            this.formData.card_code = returnData.data.公民身份号码.words;
            this.formData.address = returnData.data.住址.words;
            localStorage.setItem(
              "card_code",
              returnData.data.公民身份号码.words
            );
            this.$toast({ message: "上传成功" });
          } else {
            this.$toast({ message: "请上传身份证正面照" });
          }
        });
    },
    //上传反面照
    otherAfterRead(file) {
      const param = new FormData();
      param.append("file", file.file);
      param.append("model", "back");
      param.append("pid", this.pid);
      this.$axios
        .post("/user/uploadEducation", param, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.access_token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            this.formData.other = res.data.data.imgurl;
            this.$toast({ message: "上传成功" });
          } else {
            this.$toast({ message: "请上传身份证反面照" });
          }
        });
    },
    //上传银行卡照
    bankPicUpload(file) {
      const param = new FormData();
      param.append("file", file.file);
      param.append("model", "bankcard");
      param.append("pid", this.pid);
      this.$axios
        .post("/user/uploadEducation", param, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: this.access_token,
          },
        })
        .then((res) => {
          if (res.data.success) {
            let returnData = res.data.data;
            if (returnData.direction === 0) {
              this.formData.bank_pic = returnData.imgurl;
              this.formData.bank_account = returnData.result.bank_card_number;
              this.formData.bank_title = returnData.result.bank_name;
              this.$toast({ message: "上传成功" });
            } else {
              this.$toast({ message: "请上传银行卡照片" });
              return false;
            }
          } else {
            this.$toast({ message: "请上传银行卡照片" });
            return false;
          }
        });
    },
    toNextInfo() {
      if (this.formData.positive !== "" && this.formData.other !== "") {
        let pic = [];
        pic[0] = this.formData.positive;
        pic[1] = this.formData.other;
        this.formData.pid = this.pid;
        this.formData.pnid = this.pnid;
        let birthday = IdCard(this.formData.card_code, 1);
        let gender = IdCard(this.formData.card_code, 2);
        let myData = {
          pid: this.formData.pid,
          pnid: this.formData.pnid,
          pic: pic.join(","),
          bank_pic: this.formData.bank_pic,
          name: this.formData.name,
          card_code: this.formData.card_code,
          phone: this.formData.mobile,
          address: this.formData.address,
          gender: gender,
          birthday: birthday,
          bank_account: this.formData.bank_account,
          bank_title: this.formData.bank_title,
        };
        localStorage.setItem("realname", this.formData.name);
        this.$axios
          .post("/person/edits", myData, {
            headers: {
              "content-type": "application/json",
              Authorization: this.access_token,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.$toast({ message: res.data.msg });
              this.$router.push({ name: "nextInfo" });
            } else {
              this.$toast({ message: res.data.msg });
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.pre_active,.pre_actived{
  width: 1.5625rem;
  height: 1.5625rem;
  background-color: #5694ff;
  border-radius: 50%;
  text-align: center;
  line-height: 1.5625rem;
  color: #fff;
  margin-right: 0.3125rem;
}
.pre_actived{
  background-color: #fff;
  color: #ccc;
  border:1px solid #ccc;
}
.wrap {
  /* background-color: #F6F7FA; */
  padding-top: 0.625rem;
  box-sizing: border-box;
  /* padding-bottom: 1rem; */
}
.pre_top {
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  /* margin-top: 0.625rem; */
}

.pre_top_line {
  width: 7.5rem;
  height: 0.0625rem;
  background-color: rgba(51, 51, 51, 0.5);
}

.pre_top_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pre_flex_center {
  display: flex;
  align-items: center;
}

.pre_info_img {
  width: 1.5625rem;
  height: 1.5625rem;
}
.pre_explain {
  background-color: #e2efff;
  color: #2694fd;
  text-align: left;
  height: 2rem;
  font-size: 1rem;
  line-height: 2rem;
  padding: 0 1.5625rem;
  box-sizing: border-box;
  margin-top: 0.3125rem;
}

.pre_photo_title {
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  margin-top: 0.625rem;
}

.pre_photo {
  width: calc(100% - 1.875rem);
  margin: 0 auto;
  border: 0.0625rem solid #ddd;
  height: 9.25rem;
  border-radius: 10px;
  /* box-sizing: border-box; */
  margin-top: 0.625rem;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.pre_photo_img {
  width: 10.25rem;
  height: 7.5rem;
  margin-left: 0.625rem;
}

.pre_photo_plus {
  width: 3.75rem;
}

.pre_plus_out {
  width: 100%;
  height: 7.5rem;
}

.pre_plus_img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.625rem;
}

.pre_plus_span {
  font-size: 0.875rem;
  text-align: center;
  color: #aaa;
}
.pre_marginb {
  margin-bottom: 5rem;
}
.pre_tab {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 3.125rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  z-index: 100;
}

.pre_tabL {
  width: 20%;
  text-align: center;
  line-height: 3.125rem;
  background-color: #fff;
  color: #4494ff;
}

.pre_tabR {
  width: 80%;
  text-align: center;
  line-height: 3.125rem;
  background-color: #4494ff;
  color: #fff;
  font-size: 0.875rem;
}

.font_size {
  font-size: 1.875rem;
}
</style>
<style>
</style>
